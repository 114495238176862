/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Hind_300Light = require('./Hind_300Light.ttf');
export const Hind_400Regular = require('./Hind_400Regular.ttf');
export const Hind_500Medium = require('./Hind_500Medium.ttf');
export const Hind_600SemiBold = require('./Hind_600SemiBold.ttf');
export const Hind_700Bold = require('./Hind_700Bold.ttf');
